<template>
  <div class="col main_introduce_yourself_wrapper">
    <!-- v-if="!userLoad" -->
    <card>
      <!-- Start Why this is important  -->
      <div class="importance_wrapper">
        <!-- <h4 class="title" :class="{ 'text-right': isRtl() }">
          {{ localization("Why this is important") }}
        </h4> -->

        <h4
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_titles_content[0]"
          v-html="team_pages_titles_content[0].body"
        ></h4>
        {{ team_pages_titles_content[0].body }}

        <div
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_content[0]"
          v-html="team_pages_content[0].body"
        ></div>
      </div>
      <!-- End Why this is important  -->

      <!-- Start Collapse  -->
      <el-collapse multiple v-model="activeName">
        
        <div v-for="(statement, i) in userStatements" :key="i">
          <el-collapse-item
            :name="i"
            :class="{ reverse_while_arabic: isRtl() }"
          >
            <template #title>
              <div :class="{ 'w-100 text-right': isRtl() }">{{ i }}</div>
            </template>
            <ul
              class="main_statements_container list-unstyled"
              v-for="(item, i) in statement"
              :key="i"
            >
              <li class="every_li_wrapper">
                <h4
                  class="every_pattern_key"
                  :style="`color: ${getProgressColor(i)}`"
                  :class="{ 'd-flex flex-row-reverse': isRtl() }"
                >
                  <i
                    :class="`fa fa-star mr-1 ${i}`"
                    :style="`color: ${getProgressColor(i)}`"
                  ></i>
                  <span :class="{ 'mr-1': isRtl() }">{{ i }}</span>
                </h4>

                <ul class="second_list" :class="{ second_with_rtl: isRtl() }">
                  <li class="every_item">
                    <div v-for="(pat, i) in item" :key="i">
                      <div class="for_avoid_etc_words">{{ i }}</div>

                      <div v-if="pat">
                        <div v-for="state in pat" :key="state.id">
                          <el-checkbox-button
                            v-model="selectedItem"
                            :label="state.id"
                            @change="selectedStatements(state.id)"
                            :checked="
                              selectedItem.length > 0 &&
                                selectedItem.includes(state.id)
                            "
                            :class="{
                              'when_checked_label  is_all_checked':
                                selectedItem.length > 0 &&
                                selectedItem.includes(state.id)
                            }"
                          >
                            <span
                              class="span_in_check_statement"
                              :class="{
                                with_true:
                                  selectedItem.length > 0 &&
                                  selectedItem.includes(state.id)
                              }"
                            >
                              <i class="fa fa-check checker"></i>
                            </span>

                            <span class="wrap_while_exist">{{
                              state.statement
                            }}</span>
                          </el-checkbox-button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </el-collapse-item>
        </div>
      </el-collapse>
      <!-- End Collapse  -->

      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/teams"
          ><i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("Back to team") }}
        </router-link>
        <button class="back_link" @click="TeamStatementsAnswers">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </button>
        <!-- <router-link class="back_link" to="/building-team">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </router-link> -->
      </div>
    </card>
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      selected: [],
      selected2: [],
      activeName: "all",
      selectedItem: [],
      selectedItems: [],
      checkTeamStatementsLength: null,
      getAllStatements: {}
    };
  },
  components: {
    Card
  },
  mounted() {
    // this.Refresh();
    this.Refresh();
    this.handlerRefresh();
    // cards_titles
    this.$store.dispatch("user/get_all_teams_content");
    // document.querySelectorAll(".when_checked_label").forEach(element => {
    //   element.children[0].classList.add("is-checked");
    // });
  },
  created() {
    this.Refresh();
    this.handlerRefresh();
  },
  computed: {
    ...mapGetters({
      userStatements: ["attributes/get_user_statements"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      userLoad: ["user/userLoad"]
    }),
    selectedItemsList() {
      return this.selectedItems.map(item => item);
    }
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("attributes/getUserStatements", { query: query })
        .then(res => {
          this.getAllStatements = res.my_statements;
        });
    },
    handlerRefresh() {
      // this.selectedItem = [2, 3];
      this.$store.dispatch("user/user", this.auth().id).then(() => {
        let everyUserStatements = this.$store.state.user.user
          .my_team_statements;
        if (everyUserStatements != null) {
          this.selectedItem = JSON.parse(
            // this.selected2 = JSON.parse(
            this.$store.state.user.user.my_team_statements
          );
        }
        this.checkTeamStatementsLength = this.selectedItem.length;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    saveUpdates(id) {
      let selectedIds = [];
      selectedIds.push(id);

      if (this.selected.length >= 1) {
        const arr2 = this.selected.split("");
        let again = [];
        arr2.filter(element => {
          if (element >= 1) {
            again.push(parseInt(element));
          }
        });
        let allSelected = [...again];

        if (allSelected.includes(id)) {
          let tt = allSelected.indexOf(id);
          if (tt > -1) {
            allSelected.splice(tt, 1);
          }
        } else {
          allSelected.push(id);
        }
      } else {
      }
    },
    selectedStatements(stateId) {
      this.$store
        .dispatch("attributes/updateIntroducingStatements", {
          id: this.auth().id,
          query: { my_team_statements: this.selectedItem }
        })
        .then(() => {
          this.Refresh();
          this.handlerRefresh();
        });
    },

    // check if the user answers all questions or not
    TeamStatementsAnswers() {
      let mainObject = this.getAllStatements;
      let mainArray = [];
      let mainArraysContainer = [];
      // First -- loop in the main object to get every object item
      for (let key1 in mainObject) {
        if (
          mainObject.hasOwnProperty(key1) &&
          typeof mainObject[key1] === "object"
        ) {
          // Number 2 - loop in every object to get every object item in it
          for (let key2 in mainObject[key1]) {
            if (
              mainObject[key1].hasOwnProperty(key2) &&
              typeof mainObject[key1][key2] === "object"
            ) {
              // this line if you want all objects inside on array
              mainArray = mainArray.concat(
                ...Object.values(mainObject[key1][key2])
              );
              // Number 3 - loop in the object also to get all arrays inside one array
              for (let prop in mainObject[key1][key2]) {
                if (mainObject[key1][key2].hasOwnProperty(prop)) {
                  mainArraysContainer.push(mainObject[key1][key2][prop]);
                }
              }
            }
          }
        }
      }
      //  -- get the user selected items
      let userSelectedItemsArray = this.selectedItem;
      // Check if userSelectedItemsArray contains at least one ID from each inner array
      let results = mainArraysContainer.map(innerArray => {
        let containsAtLeastOneId = innerArray.some(obj =>
          userSelectedItemsArray.includes(obj.id)
        );
        return { innerArray, containsAtLeastOneId };
      });
      let allArraysContainAtLeastOneId = results.every(
        result => result.containsAtLeastOneId
      );
      if (allArraysContainAtLeastOneId == false) {
        Swal.fire({
          text: `${this.localization("complete team questions")}`,
          confirmButtonText: `${this.localization("Ok")}`,
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        });
      } else {
        this.$router.push("/building-team");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main_introduce_yourself_wrapper {
  .team_routes_wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    .back_link {
      color: #777;
      background-color: #eee;
      padding: 8px 10px;
      display: block;
      width: fit-content;
      margin: 10px 0;
      border-radius: 8px;
      transition: 0.3s;
      border: none;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  .importance_wrapper {
    color: #777;
    margin-bottom: 3rem;
    h4 {
      margin-top: 10px;
    }
    ol {
      li {
        margin-bottom: 10px;
        line-height: 1.7;
      }
    }
  }

  .reverse_while_arabic {
    .el-collapse-item__header {
      color: red;
    }
  }

  .main_statements_container {
    padding-top: 15px;

    .every_pattern_key {
      margin: 0 0 15px 0;
    }
    .second_list {
      list-style: none;
      &.second_with_rtl {
        text-align: right;
        padding-right: 40px;
        padding-left: 0;
      }
    }
  }

  .for_avoid_etc_words {
    color: #777;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 8px;
    position: relative;
  }
}

::v-deep .card label {
  text-transform: initial;
}

::v-deep .el-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
::v-deep .el-checkbox__label {
  // word-break: break-all !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #9a9a9a;
}
::v-deep .el-collapse-item__header {
  height: 70px;
}

::v-deep .el-checkbox-button {
  .el-checkbox-button__original {
  }
  .el-checkbox-button__inner {
    border: none;
    background: #fff !important;
    display: flex;
    gap: 8px;
    .span_in_check_statement {
      border: 1px solid #ccc;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 4px;
      .checker {
        font-size: 9px;
        display: none;
      }
      &.with_true {
        background-color: #409eff;
        color: #fff;
        border: 1px solid #409eff;
        .checker {
          font-size: 9px;
          display: block;
        }
      }
    }
    &:hover {
      color: #000;
    }
  }
  &.is_all_checked {
    .el-checkbox-button__inner {
      // background: #77e311 !important;
      color: #409eff;
    }
  }
  .wrap_while_exist {
    color: #000;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.3;
  }
}
</style>

<style lang="scss">
.main_introduce_yourself_wrapper {
  .second_with_rtl {
    .el-checkbox {
      flex-direction: row-reverse;
    }
  }
}
// .when_checked_label{

// }
</style>
